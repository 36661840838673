import React, { useEffect, useRef } from "react";
import headerLogo from "../img/logo-main.svg";
import footerLogo from "../img/footer_logo.svg";
import footerLogoMob from "../img/footer_logo-mob.svg";
import {
  setIsAuthenticated,
  setShowAuth,
  setUserInfo,
} from "../features/auth/authSlice.js";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import Login from "./Index/Login.js";

export default function Rules() {
  const dispatch = useDispatch();
  const privateAxios = useAxiosPrivate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const mobhead = useRef();
  const burger = useRef();

  useEffect(() => {
    document.body.classList.forEach((item) =>
      document.body.classList.remove(item)
    );
    document.body.classList.add("body-rules");
  }, []);

  function toggleMob(e) {
    e.preventDefault();
    document.querySelector("#mobile_menu_toggle").classList.toggle("active");
    document.querySelector(".mobile__menu").classList.toggle("active");
    document.querySelector(".js-login-bg").classList.toggle("active");

    document.querySelector(".top__hamburger").classList.toggle("active");
    document.body.classList.toggle("hidden");
  }

  async function toggleAuth(e, isMobile) {
    e.preventDefault();

    if (!isAuthenticated) {
      if (isMobile) {
        toggleMob(e);
      }
      dispatch(setShowAuth(true));
      return;
    }

    try {
      await privateAxios.delete("/auth/logout");
      localStorage.clear();
      dispatch(setIsAuthenticated(false));
      dispatch(setUserInfo({}));
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <header className="header header-rules-page">
        <div className="header__container">
          <div className="header__wrapper">
            <div className="header__block">
              <div className="header__logo">
                <a href="/">
                  <img src={headerLogo} alt="logo" />
                </a>
              </div>
              <nav className="header__nav">
                <ul className="header__list">
                  <li>
                    <a href="/#rules">Правила конкурса</a>
                  </li>
                  <li>
                    <a href="/#prizes">Призы</a>
                  </li>
                  <li>
                    <a href="/#voting">Вопросы и ответы</a>
                  </li>
                </ul>
                <button
                  onClick={(e) => toggleAuth(e)}
                  className="header__button-block header__button-real js-header__button-block"
                >
                  <span>{isAuthenticated ? "Выйти" : "Войти"}</span>
                  <svg
                    width="91"
                    height="44"
                    viewBox="0 0 91 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.052 0.28748C57.8075 0.219193 79.9174 -0.425051 85.989 4.88577C92.0606 10.1966 92.3821 30.8606 88.2079 37.8403C85.079 43.0714 82.1271 44.2664 41.0316 43.9539C12.7935 43.7398 8.78331 42.8581 4.00095 38.0956C-0.781407 33.3331 -1.77633 13.5212 3.77912 6.92948C11.4983 -2.22711 31.0715 0.351962 44.052 0.28748Z"
                      fill="white"
                      fill-opacity="0.3"
                    />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="header__mobile mobile__menu">
        <a onClick={toggleMob} id="mobile_menu_toggle" href="#">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <div className="top__hamburger">
        <div className="top__hamburger-cover">
          <div className="top__hamburger-logo">
            <a href="./">
              <img src={headerLogo} alt="logo" />
            </a>
          </div>
          <ul>
            <li>
              <a href="/#rules">Правила конкурса</a>
            </li>

            <li>
              <a href="/#prizes">Вопросы и ответы</a>
            </li>
            <li>
              <a href="/#voting"> Призы</a>
            </li>
          </ul>
          <button
            onClick={toggleAuth}
            className="button-special join__button header-hamb-btn js-header__button-block-mob"
          >
            <span className="">Войти</span>
            <svg
              width="188"
              height="45"
              viewBox="0 0 188 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M91.0085 0.78748C119.426 0.719193 165.104 0.0749492 177.648 5.38577C190.191 10.6966 190.855 31.3606 182.232 38.3403C175.768 43.5714 169.669 44.7664 84.7685 44.4539C26.4305 44.2398 18.1457 43.3581 8.2657 38.5956C-1.61434 33.8331 -3.66979 14.0212 7.80742 7.42948C23.7547 -1.72711 64.1916 0.851962 91.0085 0.78748Z"
                fill="white"
                fill-opacity="0.3"
              />
            </svg>
          </button>
        </div>
      </div>

      <main>
        <div className="rules">
          <div className="rules__wrapper">
            <div className="rules__container">
              <div className="rules__title">
                Правила и условия участия в конкурсе «Конкурс талантов»
              </div>
              <ul class="rules__main-list">
                <li>
                  1. Общие положения
                  <ul class="rules-sublist">
                    <li>
                      1.1. Наименование конкурса «Конкурс талантов» (далее –
                      «Конкурс»).
                    </li>
                    <li>
                      1.2. Организатором конкурса «Конкурс талантов» является
                      Общество с ограниченной ответственностью «ДМ»:
                      ОГРН1237770205232 <br />
                      Юридический адрес: 119415, г. Москва, пр. Вернадского, д.
                      37, корп. 3 <br />
                      Почтовый адрес: 127238, г. Москва, 3-й Нижнелихоборский
                      пр-д, д. 3 с. 6 (далее — Организатор).
                    </li>
                    <li>
                      1.3. Партнёром акции является ООО «Контекстно-Медийные
                      Технологии»:ОГРН 1126829002520 <br />
                      Юридический адрес: 392036, Тамбовская область, г. Тамбов,
                      ул. Коммунальная, д. 18, кв. 3 <br />
                      Почтовый адрес: 392036, Тамбовская область, г. Тамбов, ул.
                      Коммунальная, д. 18, кв. 3
                    </li>
                    <li>
                      1.4. Конкурс «Конкурс талантов» направлен на поддержание
                      интереса к сети магазинов «Детский мир»,
                      <br />а также на его популяризацию и продвижение. <br />
                      Конкурс не является публичным конкурсом в смысле гл. 57
                      Гражданского кодекса Российской Федерации (далее – «РФ»),{" "}
                      <br />
                      не является лотереей в смысле Федерального закона РФ от
                      11.11.2003 № 138-ФЗ «О лотереях», участие в нем не связано{" "}
                      <br />с внесением платы Участниками и не основано на
                      риске.
                    </li>
                  </ul>
                </li>
                <li>
                  2. В Конкурсе могут принять участие только лица,
                  соответствующие всем <br />
                  следующим требованиям (далее – «Участники»):
                  <ul class="rules-page__sublist">
                    <li>граждане Российской Федерации;</li>
                    <li>
                      граждане РФ в возрасте от 5 (пяти) до 15 (пятнадцати) лет
                      в лице своих законных представителей
                    </li>
                    <li>
                      Необходимые действия по участию в Конкурсе осуществляет
                      Законный представитель Участника:
                    </li>
                    <li>
                      -принимает решение об участии в Конкурсе и о размещении
                      изображения Участника на сайте Конкурса, заполняет
                      достоверные персональные, контактные данные, предоставляет
                      согласие на обработку персональных данных, загружает
                      видеоматериалы, соблюдает законодательство об
                      интеллектуальной собственности, предоставлять необходимые
                      материалы, сведения и документы по запросу Организатора
                      или Партнера.
                    </li>
                    <li>
                      К участию в Конкурсе не допускаются сотрудники
                      Организатора, аффилированных с ним компаний, <br /> а
                      также членов их семей.
                    </li>
                  </ul>
                </li>
                <li>
                  3. Место проведения конкурса
                  <ul>
                    <li>
                      3. Конкурс проводится на территории Российской Федерации.
                    </li>
                  </ul>
                </li>
                <li>
                  4. Сроки проведения конкурса
                  <ul>
                    <li>
                      Общий срок проведения Конкурса — с 00:00 9 сентября 2024
                      года по 23:59 19 октября 2024 года включительно <br />
                      (далее – Период проведения Конкурса), из них:
                    </li>
                    <li>
                      период приема заявок на участие в Конкурсе с 00:00
                      9.09.2024 – 23:59 29.09.2024 г. (включительно);
                    </li>
                    <li>
                      период определения победителей в Конкурсе с 00:00
                      30.09.2024 по 23:59 02.10.2024 г. (включительно);
                    </li>
                    <li>
                      период размещения информации о победителях с 00:00
                      03.10.24 по 23:59 03.10.24 (включительно);
                    </li>
                    <li>
                      период связи с победителями с 00:00 03.10.2024 по 23:59
                      11.10.24 (включительно);
                    </li>

                    <li>
                      период выдачи призов с 00:00 19.10.2024 по 23:59
                      19.10.2024 (включительно).
                    </li>
                    <li>
                      4.1. Любое время, указанное в настоящих Правилах,
                      считается по Московскому времени.
                    </li>
                  </ul>
                </li>
                <li>
                  5. Условия участия в Конкурсе
                  <ul>
                    <li>
                      5.1. Для того чтобы принять участие в Конкурсе, Участнику
                      необходимо в Период приёма заявок на участие в Конкурсе
                      <br />с 9.09.2024 по 29.09.2024 г. (включительно):
                    </li>
                    <li>
                      5.1.1. С 9.09.2024 по 29.09.2024 г. выполнить Творческую
                      работу – снять видео, где участник (ребенок) поет <br />
                      под минусовую фонограмму или аккомпанемент одной из песен,
                      указанных в приложении к настоящим правилам.
                    </li>
                    <li>
                      5.1.2. С 9.09.2024 по 29.09.2024 г. авторизоваться на
                      сайте конкурса{" "}
                      <a target="_blank" href="http://like.detmir.ru/">
                        like.detmir.ru
                      </a>{" "}
                      и загрузить на сайт получившуюся
                      <br />
                      творческую работу длительностью 30 – 60 секунд в формате
                      видео mp4, mov, avi, размером не более 150 Мб, указав ФИО
                      участника, номер телефона, почту, город участника,
                      возраст, а также выбрав из выпадающего <br />
                      списка песню, которую участник исполняет в своей
                      творческой работе. Участники, выполнив указанные условия,
                      тем самым подтверждают, что полностью ознакомлены и
                      согласны с настоящими Правилами.
                    </li>
                    <li>
                      5.1.3. В период с 00:00 03.10.24 по 23:59 10.10.24
                      участник должен ознакомиться со списком победителей
                      Конкурса на сайте
                      <br />{" "}
                      <a target="_blank" href="http://like.detmir.ru/">
                        like.detmir.ru
                      </a>
                      . Партнёр акции связывается с победителями конкурса в
                      течении времени, указанного в п 4., посредством <br />
                      отправки сообщения на электронный адрес, указанный
                      участником при загрузке творческой работы на{" "}
                      <a target="_blank" href="http://like.detmir.ru/">
                        like.detmir.ru
                      </a>{" "}
                      .
                    </li>
                    <li>
                      5.2. В случае несоответствия критериям, перечисленным в
                      пункте
                    </li>
                    <li>
                      5.1., а также требованиям п.2. настоящих правил,
                      <br />
                      Организатор вправе исключить Участника из конкурса,
                      направив соответствующее уведомление по электронной почте.
                      <br />
                      Участника о таком исключении в течение 10 (десяти) рабочих
                      дней с момента исключения Участника из конкурса.
                    </li>
                    <li>
                      5.3. Каждое загруженное видео на
                      <a target="_blank" href="http://like.detmir.ru/">
                        like.detmir.ru
                      </a>
                      является заявкой на участие в Конкурсе при условии
                      выполнения
                      <br />
                      остальных условий конкурса, указанных в п.5.1 настоящих
                      Правил. Количество заявок для участия в Конкурсе от одного
                      <br />
                      участника - одно видео.
                    </li>
                  </ul>
                </li>
                <li>
                  6.Определение победителей Конкурса
                  <ul>
                    <li>
                      6.1. По результатам конкурса определяется 3 (три)
                      Победителя.
                    </li>
                    <li>
                      6.2. Первый победитель конкурса выбирается путём открытого
                      голосования на сайте
                      <a target="_blank" href="http://like.detmir.ru/">
                        like.detmir.ru
                      </a>
                      . <br />
                      Участник, чья работа наберёт наибольшее количество
                      «Лайков» под видео, становится победителем конкурса.
                    </li>
                    <li>
                      6.3. Для целей определения второго и третьего победителей
                      Конкурса Партнёр конкурса создаёт конкурсную комиссию.
                      <br />
                      Состав конкурсной комиссии определяется Партнёром.
                    </li>
                    <li>
                      6.4. Работы, отобранные конкурсной комиссией Партнёра, и
                      являющиеся Творческими работами, авторы которых выполнили
                      <br />
                      все условия из п. 5.1 и соответствуют требованиям из п.2.
                      участвуют в розыгрыше призов, указанных в п.7 настоящих
                      Правил.
                    </li>
                    <li>
                      6.5. Определение победителей Конкурса, а также
                      распределение Призового фонда между ними проводится
                      конкурсной
                      <br />
                      комиссией Партнёра в период с 30.09.2024 г. по 03.10.2024
                      г. (включительно).
                    </li>
                    <li>
                      6.6. Вторым и третьим победителем становятся Участники,
                      чьи Творческие работы, по мнению конкурсной комиссии,
                      <br />
                      наилучшим образом соответствуют заданию Конкурса.
                      Оцениваются: оригинальность в исполнении, вокальные
                      данные,
                      <br />
                      артистичность, харизматичность Результаты Конкурса, равно
                      как результаты решения конкурсной комиссией пересмотру и
                      обжалованию не подлежат.
                    </li>
                    <li>
                      6.7. Информация о победителях, с указанием на конкретный
                      приз, выигранный Победителем, размещается на странице
                      <br />
                      <a target="_blank" href="http://like.detmir.ru/">
                        like.detmir.ru
                      </a>
                      в срок до 03.10.2024 г. (включительно).
                    </li>
                    <li>
                      6.7. Принимая участие в настоящем Конкурсе, Участник
                      соглашается, что все исключительные права на созданные
                      <br />в результатеучастия в данном Конкурсе в соответствии
                      с условиями задания Организатора объекты интеллектуальной
                      <br />
                      собственности принадлежат Организатору.
                    </li>
                    <li>
                      Участник Конкурса несёт ответственность за нарушение
                      авторских и иных прав третьих лиц согласно действующему
                      <br />
                      законодательству РФ. Организатор Конкурса не несёт
                      ответственности за нарушение Участником Конкурса авторских
                      <br />
                      и/или иных прав третьих лиц. В случае предъявления к
                      Организатору каких‑либо претензий и/или исков со стороны
                      <br />
                      третьих лиц в связи с нарушением вышеуказанных прав
                      Участник обязуется самостоятельно урегулировать такие
                      претензии <br />
                      и/или иски полностью, освободив Организатора от
                      ответственности, в том числе от любых выплат в пользу
                      таких лиц.
                    </li>
                  </ul>
                </li>
                <li>
                  7. Призовой фонд Конкурса.
                  <ul>
                    <li>
                      7.1. Призовой фонд Конкурса включает 3 (три) приза и
                      формируется за счет средств Партнера конкурса.
                    </li>
                    <li>7.2. Каждый победитель конкурса получает:</li>
                    <li>
                      - Сертификат на запись собственной песни в студию. В
                      сертификат входят: запись и сведение песни в студии
                      звукозаписи, работа со звукорежиссером, обработка вокала,
                      готовая запись (срок получения 7 рабочих дней). Стоимость
                      каждого сертификата составляет 16 600 рублей, включая
                      налог, который оплачивается Партнером конкурса.
                    </li>
                    <li>
                      - Именную статуэтку. Стоимость каждой статуэтки 18 000
                      рублей, включая налог, который оплачивается Партнером
                      конкурса.
                    </li>
                    <li>
                      7.3. Один победитель Конкурса вправе получить только один
                      приз.
                    </li>
                    <li>
                      7.4. Призы вручаются 19.10.2024 во время мероприятия
                      Музыкальная премия CTC Kids «СуперЛайкШоу» в концертном
                      зале “Москва”, расположенном по адресу: г. Москва,
                      проспект Андропова, д. 1. В случае нахождения победителя
                      конкурса в другом городе, Партнер конкурса организует
                      трансфер на мероприятие «Первая музыкальная премия CTC
                      Kids «СуперЛайкШоу».
                    </li>
                    <li>
                      7.4.1. Условия использования Сертификата на запись
                      собственной песни: победители могут воспользоваться
                      сертификатами <br />в течение 6 месяцев с момента
                      получения. Для того, чтобы воспользоваться сертификатом,
                      победитель должен связаться <br />
                      со студией по номеру телефона, указанном в сертификате,
                      выбрать дату записи, прибыть в студию в забронированную
                      дату
                    </li>
                    <li>
                      7.5. Партнёр акции обязуется осуществить уплату всех
                      налогов и иных существующих обязательных платежей,
                      связанных
                      <br />с предоставлением призов победителям, в соответствии
                      с действующим законодательством Российской Федерации.
                    </li>
                    <li>
                      7.6. Участники подтверждают, что уведомлены, что в случае,
                      если суммарная стоимость всех Призов, полученных
                      Участником
                      <br />в текущем налоговом периоде, превысит 4 000 (четыре
                      тысячи) рублей, у Участника возникает обязанность по
                      оплате налога <br />
                      на доходы физических лиц. Уплате подлежит налог на доходы
                      физических лиц с суммарной стоимости всех призов,
                      <br />
                      превышающей 4 000 (четыре тысячи) рублей по ставке 35% на
                      основании статьи 217 и статьи 224 Налогового Кодекса РФ.
                    </li>
                    <li>
                      Партнер уведомляет Победителей о том, что денежная часть
                      призов в сумме не более 12 110 (двенадцать тысяч сто
                      десять) руб. 00 коп. будет удержана <br />в виде налога на
                      доходы физических лиц (НДФЛ) и уплачена Партнером конкурса
                      как налоговым Агентом в соответствии <br /> с требованиями
                      законодательства в бюджет РФ.
                    </li>
                  </ul>
                </li>
                <li>
                  8. Условия и порядок получения призов Конкурса
                  <ul>
                    <li>
                      8.1. Каждый участник Конкурса обязан ознакомиться со
                      списком победителей Конкурса на странице{" "}
                      <a target="_blank" href="http://like.detmir.ru/">
                        like.detmir.ru
                      </a>
                      .
                    </li>
                    <li>
                      В период с 03.10.2024 г. по 10__.10__.2024 г. Партнёр
                      конкурса обязан связаться с Победителями по номеру
                      телефона,
                      <br />
                      указанным Победителями при загрузке творческой работы на
                      сайт конкурса для уточнения информации о сроках и порядке
                      <br />
                      выдачи призов Конкурса, о перечне и порядке передачи
                      необходимой информации и документов, которые победители
                      <br />
                      Конкурса должны предоставить для получения соответствующих
                      призов.
                    </li>
                    <li>
                      С момента передачи призов Организатором, победители
                      Конкурса несут риски его порчи или утраты.
                    </li>
                    <li>
                      8.4. В случае, если победитель Конкурса не ответ по номеру
                      телефона, указанному при загрузке творческой работы
                      <br />
                      на сайт{" "}
                      <a target="_blank" href="http://like.detmir.ru/">
                        {" "}
                        like.detmir.ru
                      </a>
                      , это приравнивается к отказу победителя от получения
                      приза, приз считается невостребованным таким <br />
                      победителем Конкурса и Организатор вправе распорядиться им
                      по своему усмотрению.
                    </li>
                    <li>
                      8.5. Призами, нераспределенными, невостребованными, а
                      также призами, от получения которых Участники отказались,
                      <br />
                      Организатор вправе распорядиться по своему усмотрению.
                      Призы не могут быть востребованы Участниками повторно.
                      <br />
                      Денежная компенсация приза Участнику не выдаётся.
                    </li>
                    <li>
                      8.6. Организатор имеет право отказать победителям Конкурса
                      в выдаче приза в случае установления факта несоблюдения
                      <br />
                      победителями Конкурса настоящих Правил.
                    </li>
                    <li>
                      8.7. Участник Конкурса соглашается со всеми правилами
                      Конкурса.
                    </li>
                  </ul>
                </li>
                <li>
                  9. Иные условия Конкурса
                  <ul>
                    <li>
                      9.1. Участвуя в Конкурсе, Участник подтверждает своё
                      согласие с тем, что Организатор вправе использовать имя,
                      фамилию, <br />
                      возраст, город, видеозапись, личное изображение
                      Участников, интервью с ними, по своему усмотрению в рамках
                      Конкурса, в том числе в рекламных целях и без
                      дополнительного согласия и денежного вознаграждения.
                      Видеозаписи Участников, <br />
                      Творческие работы, интервью и иные материалы о них
                      Участникам Конкурса не предоставляются и не возвращаются.
                    </li>
                    <li>
                      9.2. Организатор Конкурса не несёт ответственность за:
                    </li>
                    <li>
                      9.2.1. невозможность участников Конкурса ознакомиться со
                      списком победителей Конкурса, размещённым Организатором
                      <br />
                      на{" "}
                      <a target="_blank" href="http://like.detmir.ru/">
                        {" "}
                        like.detmir.ru
                      </a>
                      .
                    </li>
                    <li>
                      9.2.2. неполучение/несвоевременное получение сведений,
                      необходимых для получения призов Конкурса, по вине самих
                      <br />
                      участников Конкурса, или по вине организаций связи, или по
                      иным, не зависящим от Организатора причинам
                    </li>
                    <li>
                      9.2.3. неисполнение (несвоевременное исполнение)
                      участниками Конкурса обязанностей, предусмотренных
                      настоящими Правилами;
                    </li>
                    <li>
                      9.2.4. неполучение победителями Конкурса призов в случае
                      невостребования их или отказа от них;
                    </li>
                    <li>
                      9.2.5. жизнь, здоровье, за моральные и/или психические
                      травмы участников Конкурса, в связи с их участием.
                    </li>
                    <li>
                      9.3. Принимая участие в Конкурсе, участник подтверждает,
                      что полностью ознакомлен и согласен с настоящими
                      Правилами,
                      <br />в частности, но, не ограничиваясь нижеследующим,
                      подтверждает своё согласие:
                    </li>
                    <li>
                      9.3.1. на определение победителей Конкурса в порядке,
                      предусмотренном настоящими Правилами;
                    </li>
                    <li>
                      9.3.2. в случае, каких‑либо претензий к Организатору со
                      стороны третьих лиц в связи с нарушением участником прав
                      третьих <br />
                      лиц в ходе проведения Конкурса, Участник принимает на себя
                      ответственность по самостоятельному разрешению таких
                      споров;
                    </li>
                    <li>
                      9.3.3. на проверку подлинности сведений и материалов,
                      присланных Участниками для получения приза;
                    </li>
                    <li>
                      9.3.4. на внесение Организатором по своему усмотрению
                      изменений в настоящие Правила участия в Конкурсе. При этом
                      <br />
                      указанные изменения публикуются на сайте
                      <a target="_blank" href="http://like.detmir.ru/">
                        {" "}
                        like.detmir.ru
                      </a>{" "}
                      не позднее, чем за 3 дня до вступления указанных изменений
                      в силу;
                    </li>
                    <li>
                      9.3.5. на размещение на сайте{" "}
                      <a target="_blank" href="http://like.detmir.ru/">
                        {" "}
                        like.detmir.ru
                      </a>{" "}
                      информации об участнике Конкурса в случае победы в
                      Конкурсе, в соответствии <br />с настоящими Правилами;
                    </li>
                    <li>
                      9.3.6. на участие в рекламных интервью об участии в
                      Конкурсе, в том числе для радио и телевидения, а равно для
                      иных средств <br />
                      массовой информации, а также на осуществление
                      Организатором и/или третьими лицами по заданию
                      Организатора фото- <br />и видеосъемки участников, а также
                      на использование созданных фото — и видеозаписей с
                      участником без получения
                      <br />
                      дополнительного согласия на такое использование и без
                      уплаты какого‑либо вознаграждения за такое использование,{" "}
                      <br />в том числе в средствах массовой информации, и в том
                      числе в частности в рекламных целях, с правом передачи
                      права <br />
                      использования указанных фото — и видеозаписей с участником
                      третьим лицам;
                    </li>
                    <li>
                      9.3.7. на осуществление Организатором, а также
                      уполномоченными им лицами сбора, обработки (в том числе с
                      применением автоматизированных средств), хранения,
                      использования, распространения в целях проведения Конкурса
                      предоставленных <br />
                      Участником собственных персональных данных с соблюдением
                      необходимых мер защиты таких данных от
                      несанкционированного распространения, в целях проведения
                      настоящего Конкурса и иных подобных мероприятий,
                      формирования базы данных <br />
                      потребителей, составления статистической отчётности,
                      проведения маркетинговых исследований, а также
                      контактировать
                      <br />с Участником, в том числе, по сетям электросвязи,
                      включая направление СМС-сообщений и электронной почты, до
                      отмены <br />
                      Участником такого согласия. Участник подтверждает, что
                      уведомлен, что имеет право отозвать своё согласие,
                      отправив <br />
                      подписанное уведомление по адресу:{" "}
                      <a href="mailto:smm@detmir.ru"> smm@detmir.ru</a>.
                      Участник согласен с тем, что предоставленные им
                      персональные <br />
                      данные будут удалены по его требованию в течение 60 дней,
                      с даты получения требования об их уничтожении. Организатор
                      не несёт ответственность за неисполнение им действий,
                      связанных с проведением Конкурса, если такое <br />
                      неисполнение произошло вследствие уничтожения персональных
                      данных участника Конкурса в результате отзыва участником
                      <br />
                      согласия на их обработку. Участник Конкурса имеет право на
                      доступ к своим персональным данным. Для реализации
                      указанного права участник <br />
                      Конкурса вправе обращаться к Организатору путём
                      направления ему письменного запроса по адресу электронной
                      почты: <br />{" "}
                      <a href="mailto:smm@detmir.ru">smm@detmir.ru</a>.
                    </li>
                    <li>
                      9.4. Организатор оставляет за собой право использовать
                      присланную информацию в рамках Конкурса без специального
                      <br />
                      согласования с Участниками Конкурса и без последующего
                      денежного вознаграждения
                    </li>
                    <li>
                      9.5. Организатор оставляет за собой право не вступать в
                      письменные переговоры либо иные контакты с участниками
                      Конкурса, <br />
                      кроме случаев, предусмотренных настоящими Правилами.
                    </li>
                    <li>
                      9.6. В случае необходимости, в том числе, но, не
                      ограничиваясь, для целей вручения призов, Организатор
                      вправе потребовать <br />у участников необходимую
                      информацию, в том числе персональные данные участников,
                      для предоставления их в соответствии <br />с действующим
                      законодательством РФ в государственные органы.
                    </li>
                    <li>
                      9.7. Всю информацию о Конкурсе можно узнать на{" "}
                      <a target="_blank" href="http://like.detmir.ru/">
                        like.detmir.ru
                      </a>
                      .
                    </li>
                    <li>
                      9.8. Участник Конкурса принимает во внимание, что во время
                      проведения Конкурса в соответствии с настоящими Правилами
                      <br />
                      запрещается публиковать, распространять или иным образом
                      доводить до сведения любым лицам любую информацию,
                      которая: содержит угрозы, дискредитирует, оскорбляет,
                      порочит честь и достоинство или деловую репутацию, или
                      нарушает <br />
                      неприкосновенность частной жизни других пользователей
                      Интернет-ресурса или участников Конкурса; нарушает права
                      несовершеннолетних лиц; является вульгарной или
                      непристойной, содержит нецензурную лексику, содержит
                      порнографические изображения <br />и тексты или сцены
                      сексуального характера; содержит сцены насилия, либо
                      антигуманного обращения с животными; содержит описание
                      средств и способов суицида, любое подстрекательство к его
                      совершению; пропагандирует и/или способствует разжиганию
                      расовой, религиозной, этнической ненависти или вражды,{" "}
                      <br />
                      пропагандирует фашизм или идеологию расового
                      превосходства; содержит экстремистские материалы;
                      пропагандирует преступную деятельность или содержит
                      советы, инструкции или руководства по совершению <br />
                      преступных действий, содержит информацию ограниченного
                      доступа, включая, но не ограничиваясь, государственной и
                      коммерческой тайной, <br />
                      информацией о частной жизни третьих лиц; содержит рекламу
                      или описывает привлекательность употребления наркотических
                      веществ, информацию
                      <br />о распространении наркотиков, рецепты их
                      изготовления и советы по употреблению; носит мошеннический
                      характер; а также нарушает иные права и интересы
                      физических и юридических лиц или требования действующего
                      законодательства <br />
                      Российской Федерации.
                    </li>
                  </ul>
                </li>
                <li>
                  10. О персональных данных
                  <ul>
                    <li>
                      10.1. Принимая решение об участии в Конкурсе, а также
                      выполняя задание Организатора в сроки и порядке,
                      установленными <br />
                      настоящими Правилами, Участник (субъект персональных
                      данных) тем самым выражает и подтверждает своё согласие с
                      тем, <br />
                      что любая добровольно предоставленная им информация, а
                      также персональные данные Участника, в том числе, <br />
                      биометрические данные: изображения и видеозаписи себя и
                      своих детей может обрабатываться Организатором в
                      соответствии <br />с Политикой обработки персональных
                      данных Организатора:{" "}
                      <a href="https://www.detmir.ru/privacy_policy/">
                        https://www.detmir.ru/privacy_policy/
                      </a>
                      .
                    </li>
                    <li>
                      10.2. Под обработкой персональных данных понимается любое
                      следующее действие или совокупность таких действий, <br />
                      совершаемых с использованием средств автоматизации или без
                      использования таких средств: сбор, запись, систематизация,{" "}
                      <br />
                      накопление, хранение, уточнение (обновление, изменение),
                      извлечение, использование, блокирование, удаление,
                      уничтожение персональных данных.
                    </li>
                    <li>
                      10.3. Организатор обязан обеспечить конфиденциальность
                      персональных данных Участников Конкурса в установленном
                      <br />
                      законом порядке.
                    </li>

                    <li>
                      10.4. Состав персональных данных для целей настоящих
                      Правил — номер телефона, видеоматериалы с Участником.
                    </li>
                    <li>
                      10.5. Цель обработки персональных данных — передача
                      Победителям Приза, выполнение обязанностей по передаче
                      Приза, <br />
                      ведение реестра лиц, участвующих в Конкурсе, определение
                      победителей Конкурса.
                    </li>
                    <li>
                      10.6. Срок обработки персональных данных: с момента их
                      предоставления Участником Конкурса и до момента окончания
                      <br />
                      срока действия Конкурса и выполнения Организаторами своих
                      обязанностей по передаче Призов Победителям Конкурса.
                    </li>
                    <li>
                      10.7. Обработка персональных данных будет осуществляться
                      Организатором с соблюдением принципов и правил,
                      <br />
                      предусмотренных Федеральным законом РФ № 152-ФЗ от 27 июля
                      2006 г. «О персональных данных» <br />
                      (далее — Закон «О персональных данных»).
                    </li>
                    <li>
                      10.8. Факт участия в Конкурсе, выполнение задания
                      Организатора является свободным, конкретным,
                      информированным
                      <br />и сознательным выражением согласия Участника на
                      обработку Организатором персональных данных Участника,
                      способами, необходимыми в целях проведения Конкурса, в
                      порядке, и на условиях, предусмотренных настоящими
                      Правилами.
                    </li>
                    <li>
                      10.9. Участник Акции вправе в любое время отозвать
                      согласие на обработку персональных данных, направив
                      Организатору соответствующее уведомление заказным письмом
                      с уведомлением о вручении или иным способом, гарантирующим{" "}
                      <br />
                      получение такого отзыва Организатором. Для корректной
                      обработки отзыва согласия на обработку персональных
                      данных, <br />в таком отзыве должны содержаться как
                      минимум имя и фамилия субъекта и ссылка на личную страницу
                      в социальной сети
                      <br />
                      для однозначной идентификации Участника. В противном
                      случае Организатор может обратиться к субъекту,
                      отзывающему
                      <br />
                      согласие на обработку персональных данных, с целью
                      уточнения информации о том, на каком сайте и/или в ходе
                      какой <br />
                      активности согласие на обработку персональных данных было
                      дано.
                    </li>
                  </ul>
                </li>
                <li
                  style={{
                    textAlign: "right",
                  }}
                >
                  Приложение <br />к правилам конкурса «Конкурс талантов»
                </li>
                <li
                  style={{
                    textAlign: "center",
                  }}
                >
                  Список песен <br />
                  для участия в конкурсе «Конкурс талантов»
                  <ul
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <li>1. Малявка (Милана STAR)</li>
                    <li>2. Barbie (Милана STAR)</li>
                    <li>3. Все из-за тебя (Милана STAR)</li>
                    <li>4. Нано краска топ! (Марьяна Локель)</li>
                    <li>5. Ноль три (Марьяна Локель)</li>
                    <li>6. Не малышка (Марьяна Локель)</li>
                    <li>7. Та самая девчонка (Мирослава Быкова)</li>
                    <li>8. Не принцесса (Мирослава Быкова)</li>
                    <li>9. Сама (Мирослава Быкова)</li>
                    <li>10. Малой поплыл (Камиль Kikido)</li>
                    <li>11. Симпатяшка (Камиль Kikido)</li>
                    <li>12. Бабл Гам (Милана Филимонова)</li>
                    <li>13. Лойс (Милана Филимонова, Милана STAR)</li>
                    <li>14. Крутая (Тая Скоморохова)</li>
                    <li>15. Едем по району (Тая Скоморохова)</li>
                    <li>16. В кедах по галактике (Steshok)</li>
                    <li>17. День рождения (Steshok)</li>
                    <li>18. Флексим (Steshok)</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <footer className="footer footer-rules-page">
          <div className="footer__wrapper">
            <div className="footer__container">
              <div className="footer__block">
                <div className="footer__s">
                  <div className="footer__left">
                    <div className="footer__logo">
                      <a href="/">
                        <picture>
                          <source
                            srcSet={footerLogo}
                            media="(min-width: 550px)"
                          />

                          <img src={footerLogoMob} alt="Описание изображения" />
                        </picture>
                      </a>
                    </div>
                  </div>
                  <div className="footer__temp">
                    <div className="footer__start">
                      <div className="footer__date">
                        9 - 29 сентября 2024 года
                      </div>
                      <div className="footer__apply">Приём заявок</div>
                    </div>
                  </div>
                  <div className="footer__end">
                    <div className="footer__date">3 октября 2024 года</div>
                    <div className="footer__apply">Объявление результатов</div>
                  </div>
                </div>

                <div className="footer__botton">
                  <div className="footer__polits">
                    <div className="footer__polit">
                      <a
                        target="_blank"
                        href="https://www.detmir.ru/privacy_policy/"
                      >
                        Политика конфиденциальности
                      </a>
                    </div>
                    <div className="footer__polit">
                      <a target="_blank" href="https://bonus.detmir.ru/pdn_lk">
                        Политика обработки персональных данных
                      </a>
                    </div>
                  </div>

                  <p className="footer__botton-p">© KIDS PROJECT, 2024</p>
                  <p className="footer__botton-rights">Все права защищены</p>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <Login />
      </main>
    </>
  );
}
